<template>
  <div>
    <div class="error-img">
      <img width="100%" src="/static/images/404.png" alt="404 Image">
    </div>
    <div class="error-cont">
      <p>
        찾을수 없는 페이지 입니다.<br>
        요청하신 페이지가 사라졌거나, 잘못된 경로를 이용하셨습니다.
      </p>
      <button @click="goEvent">이벤트로 이동</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error404',
  methods:{
    goEvent() {
      location.href="/lecelle/event"
    }
  }
}
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR', sans-serif;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.error-img {
  margin: 0 auto;
  padding: 100px 40px 20px 40px;
  width: 100%;
  max-width: 420px;
}

.error-cont {
  text-align: center;
}

.error-cont p {
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  font-weight: 300;
  padding: 0 40px 15px 40px;
}

.error-cont button {
  border: 2px solid #000;
  width: 120px;
  height: 40px;
  background: #fff;
  border-radius: 4px;
}

.error-cont button:hover {
  background: #eee;
}
</style>
